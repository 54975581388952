<template>
  <div class="video_warp">
    <div class="headBar">
      <div class="leftIcon" @click.stop="$router.go(-1);">
        <!--                <svg-icon class="navArrow" iconClass="navArrow" />-->
        <img class="navArrow" src="@/assets/png/leftwhite.png" />
      </div>
    </div>

    <div
      class="video_box"
      :style="{ height: isAcg && showView ? 'calc(100vh - 60px)' : '100vh' }"
    >
      <VerticalScreen
        @buySuccess="buySuccess"
        @playerEnded="$emit('playerEnded')"
        :isAcg="isAcg"
        :activityDetail="activityDetail"
        v-if="videoShow && newVideoInfo"
        :videoInfo="newVideoInfo"
        :atlasData="videoInfo"
        @vipBuyShow="vipBuyShow"
        @goldBuyShow="goldBuyShow"
        @watchCountData="watchCountData"
        ref="verticalScreen"
      />
      <div class="layer">
        <div
          v-show="showView"
          class="left"
          :class="{ leftHeight: !(isVip && videoInfo.originCoins == 0) }"
        >
          <!--          <div>-->
          <!--            <div class="activityPayBtn" v-if="videoInfo.originCoins > 0 && !videoInfo.vidStatus.hasPaid"-->
          <!--                 @click="paying('gold')">-->
          <!--              <svg-icon icon-class="gold"/>-->
          <!--              <span>{{videoInfo.originCoins}}金币购买</span>-->
          <!--            </div>-->
          <!--            <div class="flex-aic" >-->
          <!--              <div class="activityPayBtn mr10" v-if="videoInfo.originCoins > 0 && !videoInfo.vidStatus.hasPaid"-->
          <!--                 @click="paying('gold')">-->
          <!--                <svg-icon icon-class="gold"/>-->
          <!--                <span>{{ isVip ? videoInfo.coins : videoInfo.originCoins }}金币购买</span>-->
          <!--              </div>-->
          <!--              <div class="activityPayBtn" v-if="videoInfo.coins > 0 && !videoInfo.vidStatus.hasPaid && !isVip"-->
          <!--                   @click="$router.push('/memberCentre?t=vip')">-->
          <!--                <span>开通VIP {{videoInfo.coins}}金币</span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="activityPayBtn" v-if="!isVip &&  freeWatchNum > 0 && videoInfo.originCoins==0 ">-->
          <!--              <span>免费视频剩余：{{ freeWatchNum-1 }}</span>-->
          <!--            </div>-->
          <!--            <div class="activityPayBtn" v-else-if="!isVip && !isCan && videoInfo.originCoins==0" @click="paying('vip')">-->
          <!--              <svg-icon icon-class="play2"/>-->
          <!--              <span class="vip">开通VIP观看完整版</span>-->
          <!--            </div>-->
          <!--            <div class="activityPayBtn" v-else-if="!isVip && videoInfo.originCoins==0 && isCan">-->
          <!--              <span>每日免费视频</span>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="addres ellipsis" @click="openCity">-->
          <!--            <svg-icon icon-class="city"/>-->
          <!--            <span>{{ videoInfo.location.city }}</span>-->
          <!--          </div>-->

          <div class="memberShipBuys">
            <div
              class="memberShip"
              v-if="
                !isVip &&
                !isCan &&
                videoInfo.originCoins == 0 &&
                !videoInfo.vidStatus.hasPaid &&
                !videoInfo.vidStatus.isTodayFree
              "
              @click.stop="paying('vip')"
            >
              开通会员&nbsp;畅享无限内容
            </div>
            <div
              class="goldCoin"
              v-if="
                videoInfo.coins > 0 &&
                !videoInfo.vidStatus.hasPaid &&
                !videoInfo.vidStatus.isTodayFree
              "
              @click.stop="paying"
            >
              <!--            开通会员{{ videoInfo.coins }}金币，{{ videoInfo.originCoins }}金币购买-->
              金豆解锁 畅享完整内容
            </div>
          </div>
          <div class="name_box">
            <div class="name ellipsis">
              @{{
                (videoInfo.publisher && videoInfo.publisher.name) || "未知用户"
              }}
            </div>
          </div>
          <!--          <div class="reviewTime">-->
          <!--              <span>{{videoInfo.reviewAt | commentTime}}</span>-->
          <!--          </div>-->
          <div class="title_text">
            {{ videoInfo.title }}
            {{ videoInfo.contents ? "第" + (anthologyIndex + 1) + "集" : "" }}
          </div>
          <div class="title_tag">
            <div v-for="(item, index) in videoInfo.tags" :key="index">
              {{ `#` + item.name }}
            </div>
          </div>
        </div>
        <div class="right" v-if="isAcg">
          <div class="right_content">
            <div>
              <div
                class="right_item pt13"
                @click="videoInfo.mediaStatus.hasLiked ? doUnlike() : doLike()"
                :style="{ visibility: showView ? 'visible' : 'hidden' }"
              >
                <img
                  class="icon"
                  src="@/assets/png/shortVideoZan.png"
                  v-if="!videoInfo.mediaStatus.hasLiked"
                />
                <img
                  class="icon"
                  src="@/assets/png/shortVideoZanActive.png"
                  v-else
                />
                <span>{{ videoInfo.countLike | watchCount }}</span>
              </div>

              <div
                class="right_item pt13"
                @click="
                  videoInfo.mediaStatus.hasCollected
                    ? cancelCollectionWorks()
                    : collectionWorks()
                "
                :style="{ visibility: showView ? 'visible' : 'hidden' }"
              >
                <img
                  class="icon"
                  src="@/assets/png/shortVideoUnCollect.png"
                  v-if="!videoInfo.mediaStatus.hasCollected"
                />
                <img
                  class="icon"
                  src="@/assets/png/shortVideoCollect.png"
                  v-else
                />
                <span>{{ videoInfo.countCollect | watchCount }}</span>
              </div>

              <div
                class="right_item pt13"
                @click="openComment"
                :style="{ visibility: showView ? 'visible' : 'hidden' }"
              >
                <img class="icon" src="@/assets/png/shortVideoComment.png" />
                <span>{{ videoInfo.countComment | watchCount }}</span>
              </div>
              <!-- 打赏 -->
              <!--              <div class="right_item pt13" @click.stop="reward" :style="{'visibility':showView ? 'visible':'hidden'}">-->
              <!--                <img class="icon" src="@/assets/png/giveReward.png">-->
              <!--                <span>打赏</span>-->
              <!--              </div>-->
              <div
                class="right_item pt13"
                @click.stop="openSelectLine"
                :style="{ visibility: showView ? 'visible' : 'hidden' }"
              >
                <img class="icon" src="@/assets/png/toggleLine.png" alt="" />
                <span>线路</span>
              </div>
              <div
                class="right_item pt13"
                @click.stop="toggleView"
                :style="{ visibility: showView ? 'visible' : 'hidden' }"
              >
                <img class="icon" src="@/assets/png/hiddenView.png" />
              </div>
              <div
                v-show="!showView"
                class="right_item pt13"
                @click.stop="toggleView"
              >
                <img class="icon" src="@/assets/png/showView.png" />
              </div>
            </div>
          </div>
        </div>
        <div class="right" v-else>
          <div class="right_content">
            <div>
              <div
                class="head"
                @click="
                  $router.push(`/userHomePage?uid=${videoInfo.publisher.uid}`)
                "
                :style="{ visibility: showView ? 'visible' : 'hidden' }"
              >
                <ImgDecypt
                  class="avatar"
                  :src="videoInfo.publisher && videoInfo.publisher.portrait"
                  round
                  :key="videoInfo.id"
                />
                <div
                  class="add5"
                  @click.stop="focus(videoInfo.publisher)"
                  v-if="!videoInfo.publisher.hasFollowed"
                >
                  <img
                    class="add_icon"
                    src="@/assets/png/shortVideoAttention.png"
                  />
                </div>
                <div
                  class="add5"
                  @click.stop="focus(videoInfo.publisher)"
                  v-else
                >
                  <img
                    class="add_icon"
                    src="@/assets/png/shortVideoAttentionSuccess.png"
                  />
                </div>
              </div>
              <!-- <div class="right_item pt13">
                  <img class="icon" src="@/assets/png/videoPlayFireWhite.png" />
                  <span>{{ videoInfo.playCount | watchCount}}</span>
              </div> -->
              <!--              <div class="right_item pt13" @click="like" :style="{'visibility':showView ? 'visible':'hidden'}">-->
              <!--                  <img class="icon" src="@/assets/png/shortVideoZan.png" v-if="!(videoInfo.hasLiked)">-->
              <!--                  <img class="icon" src="@/assets/png/shortVideoZanActive.png" v-else>-->
              <!--                <span>{{ videoInfo.likeCount | watchCount }}</span>-->
              <!--              </div>-->

              <div
                class="right_item pt13"
                @click="
                  videoInfo.vidStatus.hasCollected
                    ? cancelCollectionWorks()
                    : collectionWorks()
                "
                :style="{ visibility: showView ? 'visible' : 'hidden' }"
              >
                <img
                  class="icon"
                  src="@/assets/png/shortVideoZan.png"
                  v-if="!videoInfo.vidStatus.hasCollected"
                />
                <img
                  class="icon"
                  src="@/assets/png/shortVideoZanActive.png"
                  v-else
                />
                <span>{{ videoInfo.countCollect | watchCount }}</span>
              </div>

              <div
                class="right_item pt13"
                @click="openComment"
                :style="{ visibility: showView ? 'visible' : 'hidden' }"
              >
                <img class="icon" src="@/assets/png/shortVideoComment.png" />
                <span>{{ videoInfo.commentCount | watchCount }}</span>
              </div>
              <!-- 打赏 -->
              <div
                class="right_item pt13"
                @click.stop="reward"
                :style="{ visibility: showView ? 'visible' : 'hidden' }"
              >
                <img class="icon" src="@/assets/png/giveReward.png" />
                <span>打赏</span>
              </div>

              <div
                class="right_item pt13"
                @click.stop="openSelectLine"
                :style="{ visibility: showView ? 'visible' : 'hidden' }"
              >
                <img class="icon" src="@/assets/png/toggleLine.png" />
                <span>线路</span>
              </div>
              <div
                class="right_item pt13"
                @click.stop="toggleView"
                :style="{ visibility: showView ? 'visible' : 'hidden' }"
              >
                <img class="icon" src="@/assets/png/hiddenView.png" />
              </div>
              <div
                v-show="!showView"
                class="right_item pt13"
                @click.stop="toggleView"
              >
                <img class="icon" src="@/assets/png/showView.png" />
              </div>
              <!-- <div
                class="right_item pt13"
                :style="{ visibility: showView ? 'visible' : 'hidden' }"
                @click.stop="openSelectLine"
              >
                <van-icon name="sort" size="20" />
                <span class="ft12">切换线路</span>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="anthologyBox"
      v-if="isAcg && showView"
      @click="$emit('openAntholog', videoInfo)"
    >
      <div class="anthologyBtn">
        <div class="leftBox">
          <img class="icon" src="@/assets/png/anthologyIcon.png" alt="" />
          <span
            >《{{ videoInfo.title }}》共{{ videoInfo.contents.length }}集</span
          >
        </div>
        <div class="rightBox">
          <img class="icon" src="@/assets/png/moreRightIcon.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VerticalScreen from "@/components/Video/VerticalScreen";
import ImgDecypt from "@/components/ImgDecypt";
import { cancleZan, careCollectVideo, careOrcancle, zan } from "@/api/user";
import { Toast } from "vant";
import { mapGetters, mapState } from "vuex";
// import GoldBuy from "@/components/GoldBuy";
// import VipBuy from "@/components/VipBuy"
import VipCardBuy from "@/components/VipCardBuy";
import {
  doCollect,
  doLike,
  doUnlike,
  douUcollect,
  doVideoCollect,
} from "@/api/video";
import { getPresaleList } from "@/api/home";
// import Forward from "@/components/Forward"

export default {
  props: {
    videoShow: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    anthologyIndex: {
      type: Number,
      required: true,
    },
    isAcg: {
      type: Boolean,
      required: true,
    },
    videoInfo: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  components: {
    // Video,
    VerticalScreen,
    ImgDecypt,
    // GoldBuy,
    // VipBuy,
    // VipCardBuy,
    // Forward
  },
  data() {
    return {
      showView: true,
      isLoading: true, //loading
      isGoldbuyShow: false, // 金币购买弹窗
      isVipbuyShow: false, //VIP购买弹窗
      isVipCardbuyShow: false,
      freeWatchNum: 0, //免费观看次数
      isCan: false, //是否能观看 true 能观看，false不能观看 (只有会员视频才能判断)
      isRequest: false,
      vipPopList: [],
      newVideoInfo: null,
      appConfig: {},
      activityDetail: {},
    };
  },
  created() {
    this.appConfig = this.$store.getters.appConfig;

    if (
      Object.prototype.toString.call(this.$store.state.app.appConfig) ===
      "[object String]"
    ) {
      this.vipPopList = JSON.parse(this.$store.state.app.appConfig)?.pops ?? [];
    } else {
      this.vipPopList = this.$store.state.app.appConfig?.pops ?? [];
    }
    // console.log(this.videoInfo, "---");
  },
  async mounted() {
    await this.getPresaleData();
    this.newVideoInfo = this.getItemVideo();
    // console.log(this.newVideoInfo, '-==-=-')
  },
  computed: {
    ...mapGetters({
      videoApi: "videoApi",
      userInfo: "userInfo",
      isVip: "isVip",
    }),
  },
  watch: {
    anthologyIndex(oldData, newData) {
      this.$refs.verticalScreen.pauses();
      this.newVideoInfo = null;
      this.$nextTick(() => {
        this.newVideoInfo = this.getItemVideo();
      });
    },
  },
  methods: {
    async getPresaleData() {
      try {
        let req = {
          pageNumber: 1,
          pageSize: 1,
        };
        let res = await this.$Api(getPresaleList, req);
        if (res && res.code == 200) {
          this.activityDetail = res.data.activityDetail;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async collectionWorks() {
      /**
       * 收藏作品
       */
      if (this.isAcg) {
        let req = {
          kind: Number(this.videoInfo.kind),
          mediaID: this.videoInfo?.id,
        };
        let res = await this.$Api(doCollect, req);
        if (res.code == 200) {
          this.videoInfo.mediaStatus.hasCollected = true;
        }
      } else {
        let req = {
          kind: Number(this.kind),
          objID: this.videoInfo?.id,
          type: "video",
          isCollect: true,
        };
        let res = await this.$Api(doVideoCollect, req);
        if (res.code == 200) {
          this.videoInfo.vidStatus.hasCollected = true;
        }
      }
    },
    async cancelCollectionWorks() {
      /**
       * 取消收藏
       */
      if (this.isAcg) {
        let req = {
          kind: Number(this.videoInfo.kind),
          mediaID: this.videoInfo?.id,
        };
        let res = await this.$Api(douUcollect, req);
        if (res.code == 200) {
          this.videoInfo.mediaStatus.hasCollected = false;
        }
      } else {
        let req = {
          objID: this.videoInfo?.id,
          type: "video",
          isCollect: false,
        };
        let res = await this.$Api(doVideoCollect, req);
        if (res.code == 200) {
          this.videoInfo.vidStatus.hasCollected = false;
        }
      }
    },
    getItemVideo() {
      if (this.isAcg) {
        return this.videoInfo.contents
          ? this.videoInfo.contents[this.anthologyIndex]
          : {};
      } else {
        return this.videoInfo;
      }
    },
    toggleView() {
      this.showView = !this.showView;
    },
    buySuccess(val) {
      if (val) {
        this.videoInfo.contents.forEach((item) => {
          if (this.newVideoInfo.id === item.id) {
            if (this.isAcg) {
              item.mediaStatus.hasPaid = true;
            } else {
              item.vidStatus.hasPaid = true;
            }
          }
        });
        this.newVideoInfo = null;
        this.$nextTick(() => {
          this.newVideoInfo = this.getItemVideo();
        });
      } else {
        this.videoInfo.contents.forEach((item) => {
          item.mediaStatus.hasPaid = true;
        });
      }
    },
    // 打赏
    reward() {
      // this.$store.commit("user/SET_REWAE", {
      //   show: true,
      //   info: this.videoInfo
      // })
      this.$emit("reward", this.videoInfo);
    },
    forwardSuccess() {
      this.videoInfo.forwardCount++;
    },
    unfavorite() {
      this.videoInfo.forwardCount--;
    },
    openSelectLine() {
      this.$emit("openSelectLine");
    },
    //关注
    async focus(item) {
      // if (this.videoInfo?.publisher.hasFollowed) {
      //   return;
      // }
      let uid = this.videoInfo?.publisher?.uid;
      let req = {
        followUID: parseInt(uid),
        isFollow: !item.hasFollowed,
      };
      if (!uid) {
        Toast("当前用户信息错误");
        return;
      }
      try {
        let res = await this.$Api(careOrcancle, req);
        if (res.code === 200) {
          if (!item.hasFollowed) {
            this.videoInfo.publisher.hasFollowed = true;
            Toast("关注成功");
            this.$store.commit("video/UPDATE_ALL_FOCUS", uid);
          } else {
            this.videoInfo.publisher.hasFollowed = false;
            Toast("取消关注成功");
            this.$store.commit("video/UPDATE_ALL_FOCUS", uid);
          }
          return;
        }
        Toast(res.tip || "关注失败");
      } catch (e) {
        Toast("关注失败");
      }
    },
    //收藏
    async collecte() {
      let isCollect = this.videoInfo?.vidStatus?.hasCollected;
      let id = this.videoInfo?.id;
      let req = {
        type: "video",
        objID: id,
        isCollect: !isCollect,
      };
      try {
        let res = await this.$Api(careCollectVideo, req);
        if (res.code === 200) {
          this.videoInfo.vidStatus.hasCollected = !isCollect;
          if (isCollect) {
            Toast("取消点赞");
            this.videoInfo.likeCount--;
          } else {
            Toast("点赞成功");
            this.videoInfo.likeCount++;
          }
          return;
        }
        Toast("操作失败");
      } catch (e) {
        Toast("操作失败");
      }
    },
    async doLike() {
      let req = {
        kind: this.kind,
        mediaID: this.videoInfo.id,
      };
      let res = await this.$Api(doLike, req);
      if (res && res.code == 200) {
        this.videoInfo.mediaStatus.hasLiked = true;
        Toast("点赞成功");
      }
    },
    async doUnlike() {
      let req = {
        kind: this.kind,
        mediaID: this.videoInfo.id,
      };
      let res = await this.$Api(doUnlike, req);
      if (res && res.code == 200) {
        this.videoInfo.mediaStatus.hasLiked = false;
        Toast("取消点赞");
      }
    },
    //点赞
    async like() {
      let hasLiked = this.videoInfo?.vidStatus?.hasLiked;
      let id = this.videoInfo.id;
      let req = {
        // type: "media",
        type: "video",
        objID: id,
      };
      try {
        if (hasLiked) {
          let res = await this.$Api(cancleZan, req);
          if (res.code === 200) {
            this.videoInfo.vidStatus.hasLiked = false;
            this.videoInfo.likeCount--;
            Toast("取消点赞");
            return;
          }
          Toast(res.tip || "取消失败");
        } else {
          let res = await this.$Api(zan, req);
          if (res.code === 200) {
            this.videoInfo.vidStatus.hasLiked = true;
            this.videoInfo.likeCount++;
            Toast("点赞成功");
            return;
          }
          Toast(res.tip || "点赞失败");
        }
      } catch (e) {
        Toast("操作失败");
      }
    },
    //评论
    openComment() {
      this.$store.commit("user/SET_COMMENTPOP", {
        show: true,
        info: this.videoInfo,
      });

      // if (this.userInfo.isVip && this.userInfo.vipLevel) {
      //   // const data = {
      //   //   show: true,
      //   //   index: this.index,
      //   //   params: {
      //   //     videoId: this.videoInfo.id
      //   //   }
      //   // }
      //   // this.$store.commit("user/UPDATE_COMMENT", data)
      //
      //     this.$store.commit("user/SET_COMMENTPOP", {show: true, info: this.videoInfo})
      //
      // } else {
      //   Toast({
      //     message: "只有会员才能评论哦",
      //     duration: 1500,
      //   })
      // }
    },
    //打开分享
    openShare() {
      this.$store.commit("user/UPDATE_SHARE", {
        show: true,
        info: { ...this.videoInfo, isVerScreen: true },
      });
    },
    //前往标签页
    openTag(item) {
      this.$router.push({ path: "/tags", query: { id: item.id } });
    },
    //前往城市页
    openCity() {
      let id = this.videoInfo?.location?.id;
      let city = this.videoInfo?.location?.city;
      this.$router.push({ path: "/citys", query: { id: id, city: city } });
    },
    // 购买
    paying(type) {
      if (type == "vip") {
        // this.isVipbuyShow = true
        // this.$router.push('/rechargePage?t=vip')
        this.$bus.$emit("vipPopup", {
          state:
            this.appConfig.advanceStatus.activityStatus &&
            this.activityDetail.status != 4
              ? 5
              : 1,
          id: this.videoInfo.id,
          activityDetail: this.activityDetail,
          popType: "vip",
          closeBtn: () => {
            this.$bus.$emit("closeVipPopup");
          },
          buySuccess: () => {
            /**
             * 金币购买成功
             */
            this.$bus.$emit("closeVipPopup");
            if (this.isAcg) {
              this.videoInfo.mediaStatus.hasPaid = true;
            } else {
              this.videoInfo.vidStatus.hasPaid = true;
            }

            this.player.play();
            this.$store.dispatch("user/getWallet");
            // _this.getMediaInfoList();
          },
        });
      } else {
        this.$bus.$emit("vipPopup", {
          state:
            this.appConfig.advanceStatus.activityStatus &&
            this.activityDetail.status != 4
              ? 5
              : 2,
          goldenNum: this.videoInfo.coins,
          id: this.videoInfo.id,
          activityDetail: this.activityDetail,
          popType: "video",
          // videoType: 1,
          closeBtn: () => {
            this.$bus.$emit("closeVipPopup");
          },
          buySuccess: () => {
            /**
             * 金币购买成功
             */
            this.$bus.$emit("closeVipPopup");
            if (this.isAcg) {
              this.videoInfo.mediaStatus.hasPaid = true;
            } else {
              this.videoInfo.vidStatus.hasPaid = true;
            }
            this.player.play();
            this.$store.dispatch("user/getWallet");
            // _this.getMediaInfoList();
          },
        });
        // this.isGoldbuyShow = true
      }
    },
    //免费观看次数
    watchCountData(data) {
      this.freeWatchNum = data.wCount;
      this.isCan = data.isCan;
      this.isRequest = true;
    },
    //vip视频
    vipBuyShow() {
      if (this.vipPopList.length > 0) {
        // this.isVipbuyShow = true;
        this.isVipCardbuyShow = true;
      } else {
        this.isVipbuyShow = true;
      }
      // this.isVipbuyShow = true;
    },
    //金币视频
    goldBuyShow() {
      this.isGoldbuyShow = true;
    },
    //购买成功
    succcess() {
      this.isGoldbuyShow = false;
      this.$refs.verticalScreen.succcess();
    },
  },
};
</script>
<style lang="scss" scoped>
.video_warp {
  .headBar {
    //border: solid 1px red;
    display: flex;
    height: 44px;
    width: 100%;
    align-items: center;
    //border-bottom: solid 1px rgb(230,230,230);
    position: absolute;
    z-index: 5;

    .leftIcon {
      padding: 0 12px;
      .navArrow {
        width: 17px;
        height: 17px;
      }
    }

    .headTitle {
      color: rgb(255, 255, 255);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 24px;
      //font-weight: bold;
      font-size: 18px;
    }
  }

  height: 100%;

  .buy_parent {
    /deep/ .van-overlay {
      position: absolute;
    }
  }

  .video_box {
    width: 100%;
    //height: calc(100% - 60px);
    //height:100%;
    background: #000000;
    position: relative;

    .layer {
      top: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      pointer-events: none;
      text-align: left;
      z-index: 9999;

      .leftHeight {
        //height: 160px;
      }

      .left {
        position: absolute;
        bottom: 78px;
        left: 20px;
        // height: 160px;
        width: 300px;
        color: #ffffff;
        pointer-events: none;

        .addres {
          max-width: 150px;
          height: 26px;
          line-height: 26px;
          font-size: 14px;
          display: inline-block;
          background: rgba(#000000, 0.4);
          color: white;
          border-radius: 6px;
          pointer-events: auto;

          > svg {
            font-size: 15px;
            padding: 0 3px 0 5px;
          }

          > span {
            padding-right: 8px;
          }
        }

        .tag {
          display: flex;
          margin: 3px 0 5px;
          pointer-events: auto;

          .tag_item {
            //max-width: 100px;
            height: 24px;
            line-height: 24px;
            display: inline-block;
            //background: rgba(#000000, 0.4);
            font-size: 16px;
            color: white;
            border-radius: 6px;
            margin-right: 5px;
            padding: 0 5px;
            text-align: center;
          }
        }
        .memberShipBuys {
          width: 100%;
          height: 100%;
          // display: grid;
          // grid-template-columns: repeat(2, 1fr);
          // grid-gap: 12px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          pointer-events: auto;

          .memberShip {
            height: 25px;
            background: rgba(176, 159, 217, 0.2);
            border-radius: 25px;
            border: 1px solid rgba(255, 255, 255, 0.1);
            line-height: 25px;
            color: #c5adff;
            text-align: center;
            font-size: 12px;
            padding: 0 12px;
            margin-right: 12px;
          }
          .goldCoin {
            padding: 0 12px;
            height: 25px;
            background: rgba(176, 159, 217, 0.2);
            border-radius: 25px;
            border: 1px solid rgba(255, 255, 255, 0.1);
            line-height: 25px;
            color: #c5adff;
            text-align: center;
            font-size: 12px;
          }
        }

        .name_box {
          margin-top: 10px;
          display: flex;
          //padding-bottom: 5px;
          pointer-events: auto;

          .name {
            max-width: 200px;
            font-size: 16px;
            font-weight: 600;
          }

          .focus {
            margin-left: 10px;
            width: 68px;
            height: 24px;
            background: #f3792a;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            > svg {
              font-size: 12px;
            }
          }
        }

        .reviewTime {
          font-size: 12px;
          color: rgb(255, 255, 255);
          padding-bottom: 6px;
        }

        .title_text {
          margin-top: 10px;
          font-size: 12px;
          color: #ffffff;
          // display: -webkit-box;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          // white-space: normal !important;
          // text-overflow: ellipsis;
          // word-wrap: break-word;
          -webkit-line-clamp: 2;
          // -webkit-box-orient: vertical;
        }

        .title_tag {
          margin-top: 10px;
          font-size: 12px;
          color: #ffffff;
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          grid-gap: 0px;
        }

        .pay_box {
          margin-top: 5px;
          pointer-events: auto;

          .pay {
            width: 126px;
            height: 25px;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            background: #e77d2f;

            > svg {
              padding-right: 4px;
              font-size: 18px;
            }
          }

          .buied {
            font-size: 13px;
            border: 1px solid #f5164e;
            background: rgba(255, 0, 0, 0.3);
            pointer-events: none;
          }
        }
        .activityPay {
          margin-top: 5px;
          width: 280px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 17px;
          background: linear-gradient(to right, #face92, #faae6c);
          border-radius: 20px;
          color: #333333;
          pointer-events: auto;
        }
        .activityPayBtn {
          pointer-events: auto;
          display: inline-block;
          height: 32px;
          line-height: 32px;
          padding: 0 12px;
          background: rgba(0, 0, 0, 0.27);
          border-radius: 30px;
          margin-bottom: 5px;
          border: 1px solid #ffffff;
          font-size: 14px;
          svg {
            font-size: 16px;
            padding-right: 4px;
          }
          .vip {
            font-size: 14px;
          }
        }
        .mr10 {
          margin-right: 10px;
        }
      }

      .right {
        position: absolute;
        right: 16px;
        bottom: 80px;
        // height: 215px;
        // width: 44px;
        color: #ffffff;
        pointer-events: none;
        font-size: 14px;

        .right_content {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          //align-items: center;
          pointer-events: auto;

          .head {
            //width: 40px;
            //height: 40px;
            flex-shrink: 0;
            position: relative;
            margin-bottom: 10px;
            margin-left: 2px;
            .avatar {
              width: 44px;
              height: 44px;
              border: 2px solid #fff;
              border-radius: 50%;
              /deep/ .vanImage {
                background: #00000000 !important;
              }
            }

            .add5 {
              //width: 54px;
              //height: 54px;
              position: absolute;
              //bottom: -30px;
              margin-top: -10px;
              margin-left: 14px;
              z-index: 90;
              display: flex;
              align-items: center;
              justify-content: center;

              .add_icon {
                width: 20px;
                height: 20px;
              }
            }
          }

          .right_item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            line-height: 16.8px;

            .icon {
              width: 30px;
              height: 30px;
            }
            .ft12 {
              font-size: 12px;
              font-weight: bolder;
            }

            > span {
              font-size: 14px;
              padding-top: 5px;
            }

            > svg {
              font-size: 34px;
            }

            .share {
              line-height: 14px;

              /deep/ svg {
                font-size: 26px;
              }
            }
          }
        }
      }
    }
  }

  .anthologyBox {
    position: absolute;
    bottom: 36px;
    padding: 0 16px;
    width: 343px;
    z-index: 2;

    .anthologyBtn {
      //width: 343px;
      height: 42px;
      border-radius: 23px;
      background: rgba(255, 255, 255, 0.1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      font-size: 12px;
      color: #fff;
      font-weight: 500;

      .leftBox {
        display: flex;
        align-items: center;

        .icon {
          width: 20px;
          height: 20px;
          margin-right: 12px;
        }
      }

      .rightBox {
        display: flex;
        align-items: center;

        .icon {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}

.pt13 {
  padding-top: 14px;
}
</style>
