import axios from "@/utils/request";
const qs = require("qs");

// 获取专区话题媒体
export function queryTopicList(data) {
  return axios.get(`cartoon/zone/topic`, {
    params: data,
  });
}

// 获取专区话题更多列表
export function queryTopicMediaList(data) {
  return axios.get(`cartoon/topic/media`, {
    params: data,
  });
}

/**
 * 获取专题更多列表(acg)
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function queryAcgMediaList(data) {
  return axios.get(`cartoon/topic`, {
    params: data,
  });
}

/**
 * 获取专题更多列表(video)
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function queryVideoMediaList(data) {
  return axios.get(`vid/section/list`, {
    params: data,
  });
}

// 获取排行榜
// export function queryLeaderboardList(data) {
//     return axios.get(`cartoon/play/leaderboard`, {
//         params: data
//     })
// }
export function queryLeaderboardList(data) {
  return axios.get(`cartoon/rank`, {
    params: data,
  });
}

// 获取片库的类型
export function queryLibraryList(data) {
  return axios.get(`vid/library`, {
    params: data,
  });
}

//获取片库视频列表
export function queryLibrarySearchList(data) {
  return axios.post(`vid/library/search`, data);
}

// 获取视频分类的Tag列表
export function queryHotkindTagsList(data) {
  return axios.get(`cartoon/hotkind/tags`, {
    params: data,
  });
}

// 获取Tag分类视频列表
export function queryTagsmediaList(data) {
  return axios.get(`cartoon/tags/media`, {
    params: data,
    paramsSerializer: function (params) {
      return qs.stringify(params, { indices: false });
    },
  });
}

// 获取搜索Tag
export function querySearchTagsMediaList(data) {
  return axios.get(`cartoon/searchByTag`, {
    params: data,
  });
}

/**
 * 用标签获取视频列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function querySearchTagsVidList(data) {
  return axios.get(`tag/vid/list`, {
    params: data,
  });
}

/**
 * 获取acg标签视频列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function queryAcgTagsList(data) {
  return axios.get(`cartoon/tags/list`, {
    params: data,
  });
}

// 获取作品详情
export function queryMediaInfoList(data) {
  return axios.get(`cartoon/media/info`, {
    // return axios.get(`/vid/info`, {
    params: data,
  });
}

// 获取小说详情
export function queryTextInfo(data) {
  return axios.get(`cartoon/media_content/info`, {
    // return axios.get(`/vid/info`, {
    params: data,
  });
}

/**
 * 获取视频详情
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function queryVidInfo(data) {
  return axios.get(`/vid/info`, {
    params: data,
  });
}

// 使用免费次数
export function usePlayerCount(data) {
  return axios.post(`/mine/privilege/consume`, data);
}

// 购买视频
export function videoBuying(data) {
  return axios.post(`/product/buy`, data);
}

// 收藏作品
export function doCollect(data) {
  return axios.post(`cartoon/do/collect`, data);
}

// 视频模块收藏作品
export function doVideoCollect(data) {
  return axios.post(`mine/collect`, data);
}

// 取消作品收藏
export function douUcollect(data) {
  return axios.post(`cartoon/do/uncollect`, data);
}

// 播放量
export function doPlay(data) {
  return axios.post(`cartoon/do/play`, data);
}

// 获取推荐作品
export function queryRandomInfoList(data) {
  return axios.get(`cartoon/random`, {
    params: data,
  });
}

// 获取推荐作品(视频模块)
export function queryVidRandomInfoList(data) {
  return axios.get(`vid/random`, {
    params: data,
  });
}

// 获取我的书架
export function queryCollectInfoList(data) {
  return axios.get(`cartoon/collect`, {
    params: data,
  });
}
// 清空/批量取消收藏
export function batchuncollect(data) {
  return axios.post(`cartoon/do/batchuncollect`, data);
}
// 清空/批量取消收藏写真和真人
export function batchuncollect1(data) {
  return axios.post(`mine/batchUnCollect`, data);
}
// 获取已购
export function queryBroughtList(data) {
  return axios.get(`cartoon/brought`, {
    params: data,
  });
}
export function queryBuyVid(data) {
  return axios.get(`/mine/buyVid`, {
    params: data,
  });
}

//视频播放调用接口
export function getPlayVideo(data) {
  return axios.post(`vid/play`, data);
}

// 猜你想看
export function queryHotInfoList(data) {
  return axios.get(`cartoon/hot`, {
    params: data,
  });
}

// 搜索推荐关键词
export function querySearchHotWordsList(data) {
  return axios.get(`cartoon/search/hotWords`, {
    params: data,
  });
}

// 搜索作品
export function querySearchList(data) {
  return axios.get(`cartoon/search`, {
    params: data,
  });
}

// 搜索圈子
export function querySearchPostList(data) {
  return axios.post(`search/list`, data);
}

// 长视频点赞
export function doLike(data) {
  return axios.post(`cartoon/do/like`, data);
}

//视频模块视频点赞
export function doVideoLike(data) {
  return axios.post(`thumbsUp`, data);
}

// 长视频取消点赞
export function doUnlike(data) {
  return axios.post(`cartoon/do/unlike`, data);
}

//视频模块取消视频点赞
export function doVideoUnlike(data) {
  return axios.post(`thumbsDown`, data);
}
